import React from "react";
import { useSelector } from "react-redux";
import "./Results.scss";
import { NavLink } from "react-router-dom";
export default function Results() {
  const testSelector = useSelector((state) => state.test);

  return (
    <div className={"results_wrapp flex_center"}>
      <div className="card flex_col flex_start">
        {testSelector.testFinished ? (
          <>
            <h3>ВАШИ РЕЗУЛЬТАТЫ</h3>

            <span><h4>ТЕСТ: {testSelector.testName}</h4></span>
            <span><h4>Правильных ответов: {testSelector.result} из {testSelector.list.length}</h4></span>
            <center>
              <span>
              <h3>
                {testSelector.result < testSelector.passPoint
                  ? <span className="failed">ВЫ НЕ ПРОШЛИ ТЕСТ!</span>
                  : <span className="passed">ВЫ УСПЕШНО ПРОШЛИ ТЕСТ!</span>}
                  </h3>
              </span>
            </center>

            <div className="flex_row flex_around w100">
              {/* <button onClick={Restart} className="main_btn">
            Try again
          </button> */}
              <NavLink to={"/"}>
                <button className="main_btn">Вернуться к выбору теста</button>
              </NavLink>
            </div>
          </>
        ) : (
          <>
            <h2>Сначала пройдите тест</h2>
            <NavLink to={"/"}>
              <button className="main_btn">Вернуться к выбору теста</button>
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
}
