import React from "react";
import "./Test.scss";
import { useDispatch, useSelector } from "react-redux";
import { nextQuestion, answer, finish } from "../../reducers/testSlice";
import { NavLink } from "react-router-dom";
import ApiService from "../ApiService/ApiService";
import { useState } from "react";
import Results from "../Results/Results";
import Settings from "../../Settings";
import { turnLoad } from "../../reducers/authSlice";
const Test = () => {
  const [rightAnswer, setRightAnswer] = useState(null);

  const dispatch = useDispatch();
  const testSelector = useSelector((state) => state.test);

  const next = () => {
    if (!testSelector.lastQuestion) {
      dispatch(nextQuestion());
    }
  };

  const result = () => {
    dispatch(finish());
  };

  const checkAnswer = async (id) => {
    dispatch(turnLoad(true));
    let f = new FormData();
    f.append("idAnswer", id);
    f.append("idTask", testSelector.current.id);
    let r = await ApiService.getAnswer(f);
    setRightAnswer(r.id);
    dispatch(
      answer({
        idAnswer: id,
        idRight: r.id,
      })
    );
    dispatch(turnLoad(false));
  };

  return (
    <div className="test_wrapp flex_col">
      {testSelector.testName ? (
        testSelector.list.length ? (
          testSelector.testFinished ? (
            <Results />
          ) : (
            <>
              <NavLink to={"/"}>
                <button className="exit_btn">Вернуться к выбору теста</button>
              </NavLink>
              <div className="flex_row flex_vcenter flex_btw w100">
                <span className={"questions"}>
                  Вопрос {testSelector.currentId + 1} из{" "}
                  {testSelector.list.length}
                </span>
                <h3>{testSelector.testName}</h3>
              </div>

              <div className={"test_card card"}>
                <div className={"question"}>
                  <span>{testSelector.current.question}</span>
                  {testSelector.current.imageUrl && (
                    <div className={"test_img"}>
                      <img
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = Settings.noImageURL;
                          currentTarget.style.display = "none";
                        }}
                        src={Settings.mediaURL + testSelector.current.imageUrl}
                      />
                    </div>
                  )}
                </div>

                <div className={"answers"}>
                  {testSelector.current.answers.map((a, i) => {
                    return (
                      <div
                        className={`answer ${
                          a.choosen && a.id != rightAnswer && "wrong"
                        } ${a.id == rightAnswer && "right"}`}
                        onClick={() => {
                          !testSelector.current.answered && checkAnswer(a.id);
                        }}
                      >
                        {a.name}
                      </div>
                    );
                  })}
                </div>
              </div>

              <button
                className={`next_btn main_btn ${
                  !testSelector.current.answered && "disabled"
                }`}
                onClick={
                  testSelector.current.answered
                    ? testSelector.lastQuestion
                      ? result
                      : next
                    : null
                }
              >
                {testSelector.lastQuestion
                  ? "Завершить тест"
                  : "Следующий вопрос"}
              </button>
            </>
          )
        ) : (
          <div className="card m20">
            <h2>В тесте пока нет вопросов</h2>
            <NavLink to={"/"}>
              <button className="main_btn">Вернуться к выбору теста</button>
            </NavLink>
          </div>
        )
      ) : (
        <>
          <div className="card m20">
            <h2>Сначала выберите тест</h2>
            <NavLink to={"/"}>
              <button className="main_btn">Вернуться к выбору теста</button>
            </NavLink>
          </div>
        </>
      )}
    </div>
  );
};

export default Test;
