import React from "react";
import Students from "../Students/Students";
import "./Admin.scss";
import { Collapse } from "antd";
import Category from "../Category/Category";
import { useDispatch } from "react-redux";
import { logOut, turnLoad } from "../../../reducers/authSlice";

const Admin = () => {
  const dispatch = useDispatch();
  const handleExit = () => {
    dispatch(turnLoad(true));
    setTimeout(() => {
      dispatch(logOut());
    }, 500);

    setTimeout(() => {
      dispatch(turnLoad(false));
    }, 1000);
  };
  return (
    <div className={"flex_center flex_col"}>
      <div className={"admin_wrapp card m20"}>
      <button onClick={handleExit} className="exit_btn">
          Выйти
        </button>
        <center>
          <h1>Панель управления тестированием</h1>
        </center>
        <div className={"collapse_wrapp"}>
          <Collapse
            items={[{ key: "1", label: "Студенты", children: <Students /> }]}
            defaultActiveKey={["1"]}
          />
        </div>
        <div className={"collapse_wrapp"}>
          <Collapse
            items={[
              { key: "2", label: "Тесты", children: <Category /> },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Admin;
