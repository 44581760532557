import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  testName: "",
  testFinished: false,
  testStarted: false,
  current: null,
  currentId: 0,
  result: -1,
  list: [],
  lastQuestion: false,
  passPoint: 0,
};

export const testSlice = createSlice({
  name: "test",
  initialState,
  reducers: {
    initTest: (state, action) => {
      state.testName = action.payload.testName;
      state.list = action.payload.list;
      state.testFinished = false;
      state.testStarted = true;
      state.result = 0;
      state.passPoint = action.payload.passPoint;
      if (action.payload.list) {
        state.current = action.payload.list[0];
      }

      if (state.currentId + 1 == state.list.length) {
        state.lastQuestion = true;
      }
    },

    answer: (state, action) => {
      state.current.answered = true;
      state.current.answers.find(
        (x) => x.id == action.payload.idAnswer
      ).choosen = true;
      state.current.answers.find((x) => x.id == action.payload.idRight);

      if (action.payload.idAnswer == action.payload.idRight) {
        state.result++;
      }
    },

    nextQuestion: (state) => {
      state.current = state.list[++state.currentId];
      if (state.currentId + 1 == state.list.length) {
        state.lastQuestion = true;
      }
    },

    finish: (state) => {
      state.testFinished = true;
      state.testStarted = false;
    },

    clearTest: (state) => {
      state.testName = "";
      state.current = null;
      state.currentId = 0;
      state.result = -1;
      state.list = [];
      state.lastQuestion = false;
      state.testFinished = false;
      state.testStarted = false;
      state.passPoint = 0;
    },
  },
});

export const { initTest, nextQuestion, clearTest, answer, finish } =
  testSlice.actions;

export default testSlice.reducer;
