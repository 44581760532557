import React, { useEffect, useState } from "react";
import { Modal, Input, Form, Select } from "antd";
import ApiService from "../../ApiService/ApiService";
import { message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { turnLoad } from "../../../reducers/authSlice";
import { useDispatch } from "react-redux";
const Add = ({ open, setOpen, category, getCategories }) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [rightAnswer, setRightAnswer] = useState("");
  const [v1, setV1] = useState(null);
  const [v2, setV2] = useState(null);
  const [v3, setV3] = useState(null);
  const [v4, setV4] = useState(null);
  const [options, setOPtions] = useState([]);
  const dispatch = useDispatch();

  function fileToBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile(reader.result.split(",")[1]);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  useEffect(() => {
    form.resetFields();
    setOPtions([]);
    setV1(null);
    setV2(null);
    setV3(null);
    setV4(null);
  }, [open, category]);

  const addObg = () => {
    if (v1 || v2 || v3 || v4) {
      var opt = [
        {
          key: 1,
          value: v1,
        },
        {
          key: 2,
          value: v2,
        },
        {
          key: 3,
          value: v3,
        },
        {
          key: 4,
          value: v4,
        },
      ];
      setOPtions(opt);
    } else {
      setOPtions([]);
    }
  };

  const AddTask = async (data) => {
    dispatch(turnLoad(true));
    let f = new FormData();
    f.append("Question", data.question);
    f.append("ImageUrl", "");
    f.append("IdCategory", category.id);
    f.append("Answer.Id", 0);
    f.append("Answer.Name", rightAnswer);
    f.append("Answer.IdTask", 0);
    f.append("Answer.IsCorrect", true);
    f.append("Base64Img", file);
    f.append("Answer1", v1);
    f.append("Answer2", v2);
    f.append("Answer3", v3);
    f.append("Answer4", v4);

    let r = await ApiService.createTask(f);
    if (r.responseCode == "0") {
      msgSuccess("Вопрос успешно добавлен");
      getCategories();
      handleCancel();
    } else {
      msgError("Не удалось добавить вопрос");
    }
    dispatch(turnLoad(false));
  };

  const msgError = (m) => {
    messageApi.open({
      type: "error",
      content: m,
    });
  };
  const msgSuccess = (m) => {
    messageApi.open({
      type: "success",
      content: m,
    });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Добавить вопрос"
        onCancel={handleCancel}
        footer={[<></>]}
      >
        <div>
          <div className="name_wrapp">
            <Form
              form={form}
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={AddTask}
              autoComplete="off"
            >
              <Form.Item
                name="question"
                label="Вопрос"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
              <Form.Item name="ImageUrl" label="Фото">
                <Input
                  type={"file"}
                  onChange={(e) => {
                    fileToBase64(e.target.files[0]);
                  }}
                />
              </Form.Item>
              <Form.Item>
                <h4>Варианты ответов</h4>
              </Form.Item>
              <Form.Item
                name="1"
                label="А"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <TextArea
                  rows={2}
                  onInput={(data) => setV1(data.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="2"
                label="Б"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <TextArea
                  rows={2}
                  onInput={(data) => setV2(data.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="3"
                label="В"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <TextArea
                  rows={2}
                  onInput={(data) => setV3(data.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="4"
                label="Г"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <TextArea
                  rows={2}
                  onInput={(data) => setV4(data.target.value)}
                />
              </Form.Item>
              <Form.Item
              name={"rightAnswer"}
                label="Ответ"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <Select
                  onChange={(data) => {
                    setRightAnswer(data);
                  }}
                  onFocus={addObg}
                  options={options}
                />
              </Form.Item>
              <Form.Item className="flex_end">
                <input className="primary_btn" type="submit" value="Добавить" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Add;
