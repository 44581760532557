import React, { useEffect, useState } from "react";
import { Modal, Input, Form } from "antd";
import ApiService from "../../ApiService/ApiService";
import { message } from "antd";
import { turnLoad } from "../../../reducers/authSlice";
import { useDispatch } from "react-redux";
const Add = ({ open, setOpen, getCategories }) => {
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  useEffect(() => {
    form.resetFields();
    setName("");
  }, []);
 
  const AddCategory = async () => {
    dispatch(turnLoad(true));
    let f = new FormData();
    f.append("Name", name);

    const r = await ApiService.createCategory(f);
    if (r.responseCode == "0") {
      msgSuccess("Тест успешно создан");
      getCategories();
      setOpen(false);
    } else {
      msgError("Не удалось создать тест");
    }
    dispatch(turnLoad(false));
  };

  const msgError = (m) => {
    messageApi.open({
      type: "error",
      content: m,
    });
  };
  const msgSuccess = (m) => {
    messageApi.open({
      type: "success",
      content: m,
    });
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Новый тест"
        onCancel={() => {
          setOpen(false);
        }}
        footer={[<></>]}
      >
        <div>
          <div className="name_wrapp">
            <Form
              form={form}
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={(data) => AddCategory(data)}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                label="Название"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <Input onInput={(data) => setName(data.target.value)} />
              </Form.Item>
              <Form.Item className="flex_end">
                <input className="primary_btn" type="submit" value="Добавить" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Add;
