import React, { useEffect } from "react";
import { Modal, Input, Form } from "antd";
import ApiService from "../../ApiService/ApiService";
import { message } from "antd";
import { turnLoad } from "../../../reducers/authSlice";
import { useDispatch } from "react-redux";
const Add = ({ open, setOpen,getStudents }) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const msgError = (m) => {
    messageApi.open({
      type: "error",
      content: m,
    });
  };
  const msgSuccess = (m) => {
    messageApi.open({
      type: "success",
      content: m,
    });
  };
  useEffect(() => {
    form.resetFields();
  }, []);

  const makeLogin = (n) => {
    if (n) {
      var l = "";
      for (let i = 0; i < n.length; i++) {
        l += translator(n[i]);
      }
      form.setFieldsValue({ login: l });
    } else {
      form.setFieldsValue({ login: "" });
    }
  };

  const translator = (n) => {
    switch (n.toLowerCase()) {
      case "а":
        return "a";
      case "б":
        return "b";
      case "в":
        return "v";
      case "г":
        return "g";
      case "д":
        return "d";
      case "е":
        return "e";
      case "ж":
        return "j";
      case "з":
        return "z";
      case "и":
        return "i";
      case "й":
        return "i";
      case "к":
        return "k";
      case "л":
        return "l";
      case "м":
        return "m";
      case "н":
        return "n";
      case "о":
        return "o";
      case "п":
        return "p";
      case "р":
        return "r";
      case "с":
        return "s";
      case "т":
        return "t";
      case "у":
        return "u";
      case "ф":
        return "f";
      case "х":
        return "h";
      case "ц":
        return "c";
      case "ш":
        return "sh";
      case "щ":
        return "sh";
      case "э":
        return "e";
      case "ю":
        return "u";
      case "я":
        return "ia";
      case "ы":
        return "y";
      case "ё":
        return "e";
      case "ь":
        return "";
      case "ъ":
        return "";
      case " ":
        return "";
      default:
        return n.toLowerCase();
    }
  };

  const AddStudent = async (data) => {
    dispatch(turnLoad(true));
    let f=  new FormData();
    f.append("Name", data.name);
    f.append("Login", data.login);

    let r = await ApiService.createStudent(f);
    if(r.responseCode == "0"){
      getStudents();
      msgSuccess("Студент успешно добавлен")
      setOpen(false);
    }else{
      msgError("Не удалось добавить студента")
    }
    dispatch(turnLoad(false));
  };


  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
    {contextHolder}
      <Modal
        open={open}
        title="Новый студент"
        onCancel={handleCancel}
        footer={[<></>]}
      >
        <div>
          <div className="name_wrapp">
            <Form
              form={form}
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={AddStudent}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                label="Имя"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <Input
                  onInput={(data) => {
                    makeLogin(data.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item
                name="login"
                label="Логин"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <Input disabled required />
              </Form.Item>
              <Form.Item className="flex_end">
                <input className="primary_btn" type="submit" value="Добавить" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Add;
