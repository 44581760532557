import React, { useEffect, useState } from "react";
import { Table, Modal } from "antd";
import Edit from "./Edit";
import Add from "./Add";
import ApiService from "../../ApiService/ApiService";
import { message } from "antd";
const Students = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const [dataDelete, setDataDelete] = useState({});

  const [students, setStudents] = useState([]);

  const [messageApi, contextHolder] = message.useMessage();
  const msgError = (m) => {
    messageApi.open({
      type: "error",
      content: m,
    });
  };
  const msgSuccess = (m) => {
    messageApi.open({
      type: "success",
      content: m,
    });
  };
  const columns = [
    {
      title: "ФИО",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Логин",
      dataIndex: "login",
      key: "login",
    },
    {
      title: "Пароль",
      dataIndex: "password",
      key: "password",
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, data) => (
        <div className="action_wrapp flex_row">
          <button
            className="primary_btn"
            onClick={() => {
              setShowEditModal(true);
              setDataEdit(data);
            }}
          >
            Изменить
          </button>
          <button
            className="danger_btn"
            onClick={() => {
              setShowDeleteModal(true);
              setDataDelete(data.id);
            }}
          >
            Удалить
          </button>
        </div>
      ),
    },
  ];

  const getStudents = async () => {
    let r = await ApiService.getStudents();
    if (r) {
      setStudents(r);
    }
  };

  useEffect(() => {
    getStudents();
  }, []);

  const handleCancel = () => {
    setShowDeleteModal(false);
  };

  const handleOk = async () => {
    let f = new FormData();
    f.append("Id", dataDelete);

    let r = await ApiService.deleteStudent(f);
    if (r.responseCode == "0") {
      getStudents();
      msgSuccess("Студент успешно удален");
    } else {
      msgError("Не удалось удалить студента");
    }
    setShowDeleteModal(false);
  };

  return (
    <div className="students_wrapp">
      {contextHolder}
      <button
        className="primary_btn w100"
        onClick={() => setShowAddModal(true)}
      >
        + Добавить
      </button>
      <Table columns={columns} dataSource={students} />

      <Edit getStudents={getStudents} student={dataEdit} open={showEditModal} setOpen={setShowEditModal} />

      <Add
        open={showAddModal}
        setOpen={setShowAddModal}
        getStudents={getStudents}
      />

      <Modal
        open={showDeleteModal}
        title="Удаление студента"
        onCancel={handleCancel}
        onOk={handleOk}
        footer={[
          <>
            <button className="danger_btn" onClick={handleOk}>
              Удалить
            </button>
            <button className="primary_btn_outline" onClick={handleCancel}>
              Закрыть
            </button>
          </>,
        ]}
      >
        <span>Вы действительно хотите удалить студента?</span>
      </Modal>
    </div>
  );
};
export default Students;
