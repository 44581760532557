import React, { useEffect } from "react";
import "./Main.scss";
import { useDispatch } from "react-redux";
import { logOut, turnLoad } from "../../reducers/authSlice";
import { initTest, clearTest } from "../../reducers/testSlice";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import ApiService from "../ApiService/ApiService";
import Cookie from "../../store/cookie";

const Main = () => {
  const [tests, setTests] = useState([]);

  const dispatch = useDispatch();

  const handleExit = () => {
    dispatch(turnLoad(true));
    setTimeout(() => {
      dispatch(logOut());
    }, 500);

    setTimeout(() => {
      dispatch(turnLoad(false));
    }, 1000);
  };

  const getCategories = async () => {
    dispatch(turnLoad(true));

    let t = await ApiService.getCategory();
    setTests(t);
    dispatch(turnLoad(false));

  };
  const cookie = new Cookie();

  useEffect(() => {
    dispatch(clearTest());
    getCategories();
  }, []);

  const shuffle = (array) =>{
    let currentIndex = array.length; 
    let randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
  

  return (
    <div className="main_wrapp flex_col">
      <div className="user">
        <button onClick={handleExit} className="exit_btn">
          Выйти
        </button>
        <span>{cookie.getCookie("TestingAppUserName")}</span>
      </div>
      <center>
        <h3>Выбери тест</h3>
      </center>

      <div className="tests flex_center flex_wrap">
        {tests.map((test, i) => {
          return (
            <div key={i} className="test card flex_col flex_center">
              <h4>{test.name}</h4>
              <span>Вопросов - {test.tasks.length}</span>
              <NavLink to={"/test"}>
                <button
                  onClick={() => {
                    dispatch(turnLoad(true));

                    setTimeout(() => {
                      dispatch(
                        initTest({
                          testName: test.name,
                          list: shuffle(test.tasks),
                          passPoint: test.сheckpoint
                        })
                      );
                    }, 500);

                    setTimeout(() => {
                      dispatch(turnLoad(false));
                    }, 1000);
                  }}
                  className="main_btn"
                >
                  Пройти тест
                </button>
              </NavLink>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Main;
