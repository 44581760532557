import React from "react";
import "./Loader.scss";
import { useSelector } from "react-redux";

const Loader = ()=>{

    const load = useSelector((state)=>state.auth.loading);
    return(
        <div className={`loader_wrapp flex_center ${!load && "none"}`}>
            <section className="flex_center">
                <span className="loader"></span>
            </section>
        </div>
    )
}

export default Loader;