import axios from "axios";
import Settings from "../../Settings";

export default class ApiService {
  static auth = async (f) => {
    try {
      const response = await axios.post(Settings.serverURL + "/Auth", f);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };


  static getCategory = async () => {
    try {
      const response = await axios.get(Settings.serverURL + "/getCategories");
      return response.data.categories;
    } catch (error) {
      console.log(error);
    }
  };

  static getAnswer = async (f) => {
    try {
      const response = await axios.post(Settings.serverURL + "/test", f);
      return response.data.trueAnswer;
    } catch (error) {
      console.log(error);
    }
  };

  static createCategory = async (f) => {
    try {
      const response = await axios.post(
        Settings.serverURL + "/createCategoryApi",
        f
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static editCategory = async (f) => {
    try {
      const response = await axios.post(
        Settings.serverURL + "/editCategoryApi",
        f
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static deleteCategory = async (f) => {
    try {
      const response = await axios.post(
        Settings.serverURL + "/deleteCategoryApi",
        f
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static getTaskById = async (f) => {
    try {
      const response = await axios.post(Settings.serverURL + "/getTaskById",f);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static createTask = async (f) => {
    try {
      const response = await axios.post(Settings.serverURL + "/createTaskApi",f);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static deleteTask = async (task) => {
    try {
      const response = await axios.post(Settings.serverURL + "/deleteTask",task);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static editTask = async (f) => {
    try {
      const response = await axios.post(Settings.serverURL + "/editTask",f);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };



  static getStudents = async () => {
    try {
      const response = await axios.get(Settings.serverURL + "/getUsersListApi");
      return response.data.listUsers;
    } catch (error) {
      console.log(error);
    }
  };


  static createStudent = async (f) => {
    try {
      const response = await axios.post(Settings.serverURL + "/generateApi",f);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static generatePassword = async (f) => {
    try {
      const response = await axios.post(Settings.serverURL + "/generatePassword",f);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static deleteStudent = async (f) => {
    try {
      const response = await axios.post(Settings.serverURL + "/deleteUserApi",f);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static editStudent = async (f) => {
    try {
      const response = await axios.post(Settings.serverURL + "/editUserApi",f);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  
}
