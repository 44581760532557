import React, { useEffect, useState } from "react";
import { Modal, Input, Form, Select } from "antd";
import ApiService from "../../ApiService/ApiService";
import { message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useDispatch } from "react-redux";
import { turnLoad } from "../../../reducers/authSlice";
const Edit = ({ getCategories, task, open, setOpen }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [v1, setV1] = useState("");
  const [v2, setV2] = useState("");
  const [v3, setV3] = useState("");
  const [v4, setV4] = useState("");
  const [file, setFile] = useState("");
  const [rightAnswer, setRightAnswer] = useState("");
  const [options, setOPtions] = useState([{ key: 0, value: "" }]);
  const dispatch = useDispatch();
  function fileToBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile(reader.result.split(",")[1]);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const getTaskById = async () => {
    try {
      let f = new FormData();
      f.append("IdTask", task.id);
      let r = await ApiService.getTaskById(f);

      if (r.responseCode == "0") {
        form.setFieldsValue({
          rightAnswer: { label: r.task.answer.name, value: r.task.answer.id },
        });
        setRightAnswer(r.task.answer.name);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const msgError = (m) => {
    messageApi.open({
      type: "error",
      content: m,
    });
  };
  const msgSuccess = (m) => {
    messageApi.open({
      type: "success",
      content: m,
    });
  };

  useEffect(() => {
    if (task) {
      setV1(task.answers[0].name);
      setV2(task.answers[1].name);
      setV3(task.answers[2].name);
      setV4(task.answers[3].name);
      form.setFieldsValue({ answer1: task.answers[0].name });
      form.setFieldsValue({ answer2: task.answers[1].name });
      form.setFieldsValue({ answer3: task.answers[2].name });
      form.setFieldsValue({ answer4: task.answers[3].name });
      form.setFieldsValue({ question: task.question });
      getTaskById();
    }
  }, [task, open]);

  function fileToBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile(reader.result.split(",")[1]);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const addObg = () => {
    if (v1 || v2 || v3 || v4) {
      var opt = [
        {
          key: 1,
          value: v1,
        },
        {
          key: 2,
          value: v2,
        },
        {
          key: 3,
          value: v3,
        },
        {
          key: 4,
          value: v4,
        },
      ];
      setOPtions(opt);
    }
  };

  const AddTask = async (data) => {
    dispatch(turnLoad(true));
    let f = new FormData();
    f.append("Id", task.id);
    f.append("Question", data.question);
    f.append("Answer.Name", rightAnswer);
    f.append("Base64Img", file);
    f.append("Answer1", v1);
    f.append("Answer2", v2);
    f.append("Answer3", v3);
    f.append("Answer4", v4);

    let r = await ApiService.editTask(f);
    if (r.responseCode == "0") {
      msgSuccess("Вопрос успешно сохранен");
      getCategories();
      handleCancel();
    } else {
      msgError("Не удалось изменить вопрос");
    }
    dispatch(turnLoad(false));
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Добавить вопрос"
        onCancel={handleCancel}
        footer={[<></>]}
      >
        <div>
          <div className="name_wrapp">
            <Form
              form={form}
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={AddTask}
              autoComplete="off"
            >
              <Form.Item
                name="question"
                label="Вопрос"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
              <Form.Item name="ImageUrl" label="Фото">
                <Input
                  type={"file"}
                  onChange={(e) => {
                    fileToBase64(e.target.files[0]);
                  }}
                />
              </Form.Item>
              <Form.Item>
                <h4>Варианты ответов</h4>
              </Form.Item>
              <Form.Item
                name="answer1"
                label="А"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <TextArea rows={2}
                  value={v1}
                  onInput={(data) => setV1(data.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="answer2"
                label="Б"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <TextArea rows={2}
                  value={v2}
                  onInput={(data) => setV2(data.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="answer3"
                label="В"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <TextArea rows={2}
                  value={v3}
                  onInput={(data) => setV3(data.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="answer4"
                label="Г"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <TextArea rows={2}
                  value={v4}
                  onInput={(data) => setV4(data.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="rightAnswer"
                label="Ответ"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <Select
                  onFocus={addObg}
                  options={options}
                  onChange={(data) => {
                    setRightAnswer(data);
                  }}
                />
              </Form.Item>
              <Form.Item className="flex_end">
                <input
                  className="primary_btn"
                  type="submit"
                  value="Сохранить"
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Edit;
