import React, { useEffect, useState } from "react";
import { Modal, Input, Form } from "antd";
import ApiService from "../../ApiService/ApiService";
import { turnLoad } from "../../../reducers/authSlice";
import { useDispatch } from "react-redux";
const Edit = ({ data, open, setOpen, onError, onSuccess, getCategories }) => {
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      setName(data.name);
      setCount(data.сheckpoint);
    }
  }, [data]);

  const editCategory = async () => {
    dispatch(turnLoad(true));
    let f = new FormData();
    f.append("IdCategory", data.id);
    f.append("count", count);
    f.append("Name", name);
    let r = await ApiService.editCategory(f);
    if(r.responseCode == "0"){
      onSuccess("Тест успешно изменен");
      setOpen(false);
      getCategories();
    }else
    {
      onError("Не удалось изменить тест")
    }
    dispatch(turnLoad(false));
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        title="Изменение теста"
        onCancel={handleCancel}
        footer={[<></>]}
      >
        <div>
          <div className="name_wrapp">
            <Form
              form={form}
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={(data) => editCategory(data)}
              autoComplete="off"
            >
              <Form.Item
                
                label="Название"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <Input value={name} onInput={(data) => setName(data.target.value)} />
              </Form.Item>
              <Form.Item
                
                label="Кол-во ответов"
                rules={[
                  {
                    required: true,
                    message: "Поле не может быть пустым",
                  },
                ]}
              >
                <Input value={count} onInput={(data) => setCount(data.target.value)} />
              </Form.Item>
              <Form.Item className="flex_end">
                <input
                  className="primary_btn right"
                  type="submit"
                  value="Сохранить"
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Edit;
