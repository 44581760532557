import React, { useState } from "react";
import "./Auth.scss";
import { useDispatch} from "react-redux";
import { logIn, turnLoad } from "../../reducers/authSlice";
import { message } from "antd";
import ApiService from "../ApiService/ApiService";
const Auth = () => {
  const dispatch = useDispatch();

  const [login, setLogin] = useState(null);
  const [password, setPassword] = useState(null);

  const [messageApi, contextHolder] = message.useMessage();
  const msgError = (m) => {
    messageApi.open({
      type: "error",
      content: m,
    });
  };
  const msgWarning = (m) => {
    messageApi.open({
      type: "warning",
      content: m,
    });
  };
  const tryAuth = async () => {
    dispatch(turnLoad(true));
    try {
      if (login && password) {
        let f = new FormData();
        f.append("login", login);
        f.append("password", password);
        let r = await ApiService.auth(f);
        if (r.responseCode == "0") {
          setTimeout(() => {
            dispatch(logIn(r.user));
          }, 500);
        } else {
          msgError("Неверный логин или пароль");
        }
      } else {
        msgWarning("Введите логин и пароль!");
      }
    } catch (error) {
      msgWarning("Введите логин и пароль!");
    }

    setTimeout(() => {
      dispatch(turnLoad(false));
    }, 1000);
  };

  return (
    <div className="auth_wrapp flex_center">
      {contextHolder}
      <div className="auth card flex_col">
        <h3>Авторизация</h3>
        <input
          placeholder="логин..."
          value={login}
          onInput={(e) => {
            setLogin(e.target.value);
          }}
        ></input>
        <input
          placeholder="пароль..."
          type="password"
          value={password}
          onInput={(e) => {
            setPassword(e.target.value);
          }}
        ></input>
        <center>
          <button
            onClick={tryAuth}
            className="main_btn"
            placeholder="пароль..."
          >
            Войти
          </button>
        </center>
      </div>
    </div>
  );
};

export default Auth;
