import React, { useEffect, useState } from "react";
import {Collapse } from "antd";
import Edit from "./Edit";
import Add from "./Add";
import Tasks from "../Tasks/Tasks";
import ApiService from "../../ApiService/ApiService";
import { useDispatch } from "react-redux";
import { turnLoad } from "../../../reducers/authSlice";
const Category = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    dispatch(turnLoad(true));
    let r = await ApiService.getCategory();
    setCategories(r);
    dispatch(turnLoad(false));
  };

  return (
    <div className="categories_wrapp">
      <button
        className="primary_btn w100"
        onClick={() => setShowAddModal(true)}
      >
        + Добавить
      </button>
      <div>
        {categories && (
          <div className="categories_wrapp">
            {categories.map((c, i) => {
              return (
                <div key={i} className="category">
                  <Collapse
                    items={[
                      {
                        key: i,
                        label: c.name,
                        children: <Tasks category={c} getCategories={getCategories}/>,
                      },
                    ]}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Add
        open={showAddModal}
        setOpen={setShowAddModal}
        getCategories={getCategories}
      />
    </div>
  );
};
export default Category;
