import { createSlice } from "@reduxjs/toolkit";
import Cookie from "../store/cookie";
let cookie = new Cookie();
const initialState = {
  isAuth: cookie.getCookie("TestingAppUserToken"),
  login: cookie.getCookie("TestingAppUserLogin"),
  username: cookie.getCookie("TestingAppUserName"),
  role: cookie.getCookie("TestingAppUserRole"),
  loading: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logIn: (state, action) => {
      state.isAuth = true;
      state.login = action.payload.login;
      state.token = action.payload.login;
      state.username = action.payload.name;
      state.role = action.payload.role;
      let expire = new Date();
      expire.setDate(expire.getDate() + 7);
      cookie.setCookie("TestingAppUserToken", action.payload.login, expire);
      cookie.setCookie("TestingAppUserLogin", action.payload.login, expire);
      cookie.setCookie("TestingAppUserRole", action.payload.role, expire);

      cookie.setCookie("TestingAppUserName", action.payload.name, expire);
    },
    logOut: (state) => {
      state.isAuth = false;
      state.login = "";
      state.token = "";
      state.username = "";

      cookie.deleteCookie("TestingAppUserToken");
      cookie.deleteCookie("TestingAppUserLogin");
      cookie.deleteCookie("TestingAppUserRole");
      cookie.deleteCookie("TestingAppUserName");
    },

    turnLoad: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { logIn, logOut, turnLoad } = authSlice.actions;

export default authSlice.reducer;
