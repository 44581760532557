import React, { useEffect, useState } from "react";
import { Table, Modal } from "antd";
import Edit from "./Edit";
import EditNameCategory from "../Category/Edit";
import Add from "./Add";
import ApiService from "../../ApiService/ApiService";
import { message } from "antd";
import Settings from "../../../Settings";
const Tasks = ({ category, getCategories }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteTask, setShowDeleteTask] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [deletingTask, setDeletingTask] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setTasks(category.tasks);
  }, [category]);

  const columns = [
    {
      title: "",

      key: "counter",
      render: (i, data, j) => <>{j+1}</>,
    },
    {
      title: "Вопрос",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "",
      key: "imageUrl",
      render: (_, data) => (
        <>
          <div className="task_img flex_center">
            {data.imageUrl && (
              <img
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = Settings.noImageURL;
                  currentTarget.style.display = "none";
                }}
                src={Settings.mediaURL + data.imageUrl}
              />
            )}
          </div>
        </>
      ),
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, data) => (
        <div className="action_wrapp flex_row">
          <button
            className="primary_btn_outline "
            onClick={() => {
              setDataEdit(data);
              setShowEditModal(true);
            }}
          >
            Изменить
          </button>
        </div>
      ),
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, data) => (
        <div className="action_wrapp flex_row">
          <button
            className="danger_btn"
            onClick={() => {
              setDeletingTask(data.id);
              setShowDeleteTask(true);
            }}
          >
            Удалить
          </button>
        </div>
      ),
    },
  ];

  const handleDeleteTask = async () => {
    let f = new FormData();
    f.append("IdTask", deletingTask);
    let r = await ApiService.deleteTask(f);
    if (r.responseCode == "0") {
      msgSuccess("Вопрос успешно удален");
    } else {
      msgError("Не удалось удалить вопрос");
    }
    setShowDeleteTask(false);
    getCategories();
  };

  const handleDeleteCategory = async () => {
    let f = new FormData();
    f.append("IdCategory", category.id);
    let r = await ApiService.deleteCategory(f);
    if (r.responseCode == "0") {
      msgSuccess("Тест успешно удален");
      getCategories();
    } else {
      msgError("Не удалось удалить тест");
    }
    setShowDeleteModal(false);
  };

  const msgError = (m) => {
    messageApi.open({
      type: "error",
      content: m,
    });
  };
  const msgSuccess = (m) => {
    messageApi.open({
      type: "success",
      content: m,
    });
  };

  return (
    <div className="tasks_wrapp">
      {contextHolder}
      <button
        className="danger_btn w100"
        onClick={() => setShowDeleteModal(true)}
      >
        - Удалить тест
      </button>
      <button
        className="primary_btn_outline w100"
        onClick={() => setShowEditCategoryModal(true)}
      >
        Изменить тест
      </button>
      <button
        className="primary_btn w100"
        onClick={() => setShowAddModal(true)}
      >
        + Добавить вопрос
      </button>
      <Table columns={columns} dataSource={tasks} />

      {/* Изменение вопроса */}
      <Edit
        getCategories={getCategories}
        category={category}
        task={dataEdit}
        open={showEditModal}
        setOpen={setShowEditModal}
      />
      {/*  */}

      {/* Изменение теста */}
      <EditNameCategory
        data={category}
        open={showEditCategoryModal}
        setOpen={setShowEditCategoryModal}
        onSuccess={msgSuccess}
        onError={msgError}
        getCategories={getCategories}
      />
      {/*  */}

      {/* Добавление вопроса */}
      <Add
        open={showAddModal}
        setOpen={setShowAddModal}
        getCategories={getCategories}
        category={category}
      />
      {/*  */}

      {/* Удаление теста */}
      <Modal
        open={showDeleteModal}
        title="Удаление теста"
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onOk={handleDeleteCategory}
        footer={[
          <>
            <button className="danger_btn" onClick={handleDeleteCategory}>
              Удалить
            </button>
            <button
              className="primary_btn_outline"
              onClick={() => {
                setShowDeleteModal(false);
              }}
            >
              Закрыть
            </button>
          </>,
        ]}
      >
        <span>Вы действительно хотите удалить тест?</span>
      </Modal>
      {/*  */}

      {/* Удаление вопроса */}
      <Modal
        open={showDeleteTask}
        title="Удаление вопроса"
        onCancel={() => {
          setShowDeleteTask(false);
        }}
        onOk={handleDeleteTask}
        footer={[
          <>
            <button className="danger_btn" onClick={handleDeleteTask}>
              Удалить
            </button>
            <button
              className="primary_btn_outline"
              onClick={() => {
                setShowDeleteTask(false);
              }}
            >
              Закрыть
            </button>
          </>,
        ]}
      >
        <span>Вы действительно хотите удалить вопрос?</span>
      </Modal>
      {/*  */}
    </div>
  );
};
export default Tasks;
