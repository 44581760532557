import React from "react";
import { useSelector } from "react-redux";

import Test from "../Test/Test";
import Auth from "../Auth/Auth";
import Main from "../Main/Main";
import "../Main/Main.scss";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Results from "../Results/Results";
import Admin from "../AdminPanel/Main/Admin";
import Loader from "../Loader/Loader";
export default function Routing() {
  const auth = useSelector((state) => state.auth);

  return (
    <div className={"routes_wrapp flex_center"}>
      <Loader />
      <Router>
        <Switch>
          <Route path={"/"} exact>
            {auth.isAuth ? <Main /> : <Auth />}
          </Route>

          <Route path={"/test"}>{auth.isAuth ? <Test /> : <Auth />}</Route>

          <Route path={"/results"}>
            {auth.isAuth ? <Results /> : <Auth />}
          </Route>

          <Route path={"/login"}>
            <Auth />
          </Route>

          <Route path={"/admin"}>
            {auth.isAuth && auth.role == "Admin" ? <Admin /> : <Auth />}
          </Route>
        </Switch>
      </Router>
    </div>
  );
}
